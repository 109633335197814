import React, { Component } from 'react'
import store from 'store'
import { Message, Icon, Button } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import classNames from 'classnames'

const propTypes = {
  /**
   * API から返ってくるエラーオブジェクト
   */
  error: PropTypes.object,
}

const defaultProps = {
  error: null,
}

/**
 * API から返却されたエラーに対するメッセージを表示するコンポーネント
 * props として error が渡されたときに表示
 */
class ApiErrorMessage extends Component {
  state = {
    header: '', // エラーヘッダー
    message: '', // エラーメッセージ
    isHidden: true, // コンポーネントの表示状態
  }

  handleSignOutClick = () => {
    store.remove('authToken')
    store.remove('currentUser')
  }

  renderError403 = () => (
    <div>
      <div>
        権限が再設定されています。一度サインアウトをし、サインインをしてください。解決しない場合は、Slackにてご連絡ください
      </div>
      <div>
        <Button basic size="small" content="サインアウト" icon="sign out" onClick={this.handleSignOutClick} />
      </div>
    </div>
  )

  componentWillReceiveProps(nextProps) {
    const error = this.props.error
    const nextError = nextProps.error

    if (!_.isEqual(error, nextError)) {
      const partialState = {
        isHidden: _.isNull(nextError),
      }

      if (!_.isNull(nextError) && _.has(nextError, 'status')) {
        // TODO: 細かいエラー文言に関してはこちらの issue で対応 (https://github.com/trill-corp/trill-outsourced-articles-tool/issues/207)
        switch (nextError.status) {
          case 401:
            partialState.header = 'Authorization Required'
            break
          case 403:
            partialState.header = 'Forbidden'
            partialState.message = this.renderError403()
            break
          case 404:
            partialState.header = 'Not Found'
            break
          case 500:
            partialState.header = 'Internal Server Error'
            break
          case 503:
            partialState.header = 'Service Temporalily Unavailable'
            break
          default:
            partialState.message = '予期せぬエラーが発生しました'
        }
      } else if (!_.isNull(nextError)) {
        partialState.message = nextError.message
      }

      this.setState(partialState)
    }
  }

  render() {
    return (
      <Message className={classNames({ isHidden: this.state.isHidden })} negative icon>
        <Icon name="warning circle" />
        <Message.Content>
          <Message.Header>{this.state.header}</Message.Header>
          {this.state.message}
        </Message.Content>
      </Message>
    )
  }
}

ApiErrorMessage.propTypes = propTypes
ApiErrorMessage.defaultProps = defaultProps

export default ApiErrorMessage
