import React, { Component } from 'react'
import { Segment, Header } from 'semantic-ui-react'

class Error403 extends Component {
  render() {
    return (
      <Segment basic>
        <Header as="h1">403 Error</Header>
      </Segment>
    )
  }
}

export default Error403
