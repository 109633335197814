/**
 * キャンセル可能なプロミスを作成
 * @see https://github.com/facebook/react/issues/5465#issuecomment-157888325
 */
const CancelablePromise = promise => {
  let hasCanceled = false

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(val => (hasCanceled ? reject({ isCanceled: true }) : resolve(val)))
    promise.catch(error => (hasCanceled ? reject({ isCanceled: true }) : reject(error)))
  })

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true
    },
  }
}

export default CancelablePromise
