import React, { Component } from 'react'
import _ from 'lodash'
import { Button, Dimmer, Image, Loader, Message, Modal, Popup, Segment, Header, Divider } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'

import ImageCropper from './ImageCropper'
import StaticImageSelector from './StaticImageSelector'

import './MediaInput.css'

const propTypes = {
  /**
   * 初期に読み込むメディアの URL
   */
  mediaUrl: PropTypes.string,

  /**
   * ここにファイルをドラッグ アンド ドロップするか、クリックしてファイルを選択します
   */
  isDragDrop: PropTypes.bool,

  /**
   * メディアの最小の幅
   */
  minWidth: PropTypes.number,

  /**
   * メディアの最小の高さ
   */
  minHeight: PropTypes.number,

  /**
   * 許容するファイルの最大サイズ (メガバイト)
   */
  maxSize: PropTypes.number,

  /**
   * クロップ比率
   */
  aspectRatio: PropTypes.number,

  /**
   * 許容するファイル形式
   */
  accepts: PropTypes.array,

  /**
   * 指定した画像から選ぶ場合に使用する画像 URL
   */
  staticImageUrls: PropTypes.array,

  /**
   * 画像の変更時に呼び出す外部関数
   *
   * @param {SyntheticEvent} event - React の SyntheticEvent
   * @param {Object} data - 全ての props と、その他の変更に関連するデータ
   * @param {string} data.mediaUrl - 変更されたメディアの URL (許容するファイル以外を選択した場合は空文字を返す）
   * @param {Object} [data.error] - エラーオブジェクト
   * @param {InputType} data.inputType - 選択中のメディアのタイプ
   */
  onChange: PropTypes.func,
}

const defaultProps = {
  accepts: ['image/*'],
}

class MediaInput extends Component {
  /**
   * 入力メディアのタイプ
   * @enum {string}
   */
  static InputType = {
    IMAGE: 'image',
    VIDEO: 'video',
  }

  state = {
    mediaUrl: '',
    resetUrl: '', // 戻すを押した時に設定する画像 外部から指定されたURL かメディア DataURL
    isModalOpen: false,
    isBusy: false,
    isImageModified: false, // 画像がクロップされたかどうか
    message: null, // Message コンポートにセットする props を格納するオブジェクト
    isStaticImageSelectorOpen: false, // 画像選択モーダル画面の表示状態
    inputType: MediaInput.InputType.IMAGE, // 選択中のメディアの種類
  }

  isFileSelected = false
  currentMediaUrl = '' // コンポート内の現在設定中の mediaUrl

  componentWillMount() {
    // 画像の指定がある場合はローディングを表示して読み込み
    this.currentMediaUrl = this.props.mediaUrl
    // 画像の指定がない場合は、this.props.accepts から InputType を判定 (動画のみの場合は InputType.VIDEO とする)
    const accepts = this.props.accepts

    this.setState({
      mediaUrl: _.defaultTo(this.currentMediaUrl, ''),
      resetUrl: _.defaultTo(this.currentMediaUrl, ''),
      isModalOpen: false,
      isImageModified: false,
      isBusy: !_.isEmpty(this.currentMediaUrl),
      inputType:
        _.includes(accepts, 'video/mp4') && !_.includes(accepts, 'image/*')
          ? MediaInput.InputType.VIDEO
          : MediaInput.InputType.IMAGE,
      message: null,
    })
  }

  // 記事データの読み込みなどで初期に読み込むメディアの URL が変更になった場合、state.mediaUrl を更新し編集状態をリセットする
  componentWillReceiveProps(nextProps) {
    // mediaUrl が更新された場合 state に反映
    if (this.currentMediaUrl !== nextProps.mediaUrl) {
      this.currentMediaUrl = nextProps.mediaUrl

      // 画像の指定がある場合はローディングを表示して読み込み、ない場合はステートをリセット
      this.setState({
        mediaUrl: _.defaultTo(this.currentMediaUrl, ''),
        resetUrl: _.defaultTo(this.currentMediaUrl, ''),
        isModalOpen: false,
        isBusy: !_.isEmpty(this.currentMediaUrl),
        isImageModified: false,
        inputType: /.+\.mp4$/.test(this.currentMediaUrl) ? MediaInput.InputType.VIDEO : MediaInput.InputType.IMAGE,
      })
    }
    if (this.props.disableUndoButton !== nextProps.disableUndoButton && nextProps.disableUndoButton === true) {
      this.setState({
        isImageModified: false,
      })
    }
  }

  handleStaticImageSelect = imageUrl => {
    // 選択された画像を現在の画像とする
    this.currentMediaUrl = imageUrl

    // 選択した画像を戻したい場合、isImageModified を true にする
    this.setState({
      mediaUrl: this.currentMediaUrl,
      resetUrl: this.currentMediaUrl,
      isStaticImageSelectorOpen: false,
      isImageModified: false,
    })
  }

  handleStaticImageClose = () => {
    this.setState({ isStaticImageSelectorOpen: false })
  }

  handleImageClick = () => {
    this.setState({
      isModalOpen: true,
    })
  }

  handleModalClose = () => {
    this.setState({
      isModalOpen: false,
    })
  }

  handleSelectFileChange = event => {
    let files
    let message = null
    const maxSize = this.props.maxSize ? this.props.maxSize * 1024 * 1024 : null

    event.preventDefault()

    if (event.dataTransfer) {
      files = event.dataTransfer.files
    } else if (event.target) {
      files = event.target.files
    }

    const file = files[0]
    const reader = new FileReader()

    reader.onload = () => {
      // 画像読み込み後、this.handleImageLoad に処理が続きます
      this.setState({ mediaUrl: reader.result })
      this.isFileSelected = true
    }

    // キャンセルの場合は file は未定義
    if (file) {
      // エラー or ワーニングチェック
      if (/.+\.mp4$/.test(file.name) && file.type === 'video/mp4' && !_.isNull(maxSize) && maxSize < file.size) {
        // ファイルタイプが動画 (mp4) の場合で設定した最大サイズを超えていた場合はワーニングを表示
        message = _.assign(message, {
          warning: true,
          content: `${this.props.maxSize} MB 以下のファイルを選択してください。`,
        })
      } else if (!/^image\/\w+$/.test(file.type) && !(/.+\.mp4$/.test(file.name) && file.type === 'video/mp4')) {
        // ファイルタイプが画像でも動画 (mp4) でもない場合はエラーを表示
        message = _.assign(message, {
          error: true,
          content: '画像または mp4 形式の動画ファイルを選択してください',
        })
      }

      if (message && message.error) {
        this.currentVideoUrl = ''
        const error = new Error(message.content)

        this.setState({ message, mediaUrl: this.currentMediaUrl })
        _.invoke(this.props, 'onChange', event, {
          ...this.props,
          mediaUrl: this.currentMediaUrl,
          error,
        })
      } else {
        reader.readAsDataURL(file)
        const inputType = /^image\/\w+$/.test(file.type) ? MediaInput.InputType.IMAGE : MediaInput.InputType.VIDEO
        // ローディングのスタート
        this.setState({ isBusy: true, inputType, message })
      }
    }
  }

  handleImageCropperComplete = dataUrl => {
    this.currentMediaUrl = dataUrl
    this.setState({ mediaUrl: this.currentMediaUrl, isImageModified: true }, this.handleModalClose)
  }

  handleImageError = () => {
    // ERR_NAME_NOT_RESOLVED が発生した場合などエラーが出たらローディングを消す
    this.setState({
      isBusy: false,
    })
  }

  handleMediaLoad = event => {
    this.currentMediaUrl = event.target.currentSrc
    const media = event.target
    let newState = {}
    let message = this.state.message

    // 画像サイズの警告メッセージを生成
    if (!_.isNil(this.props.minWidth) && !_.isNil(this.props.minHeight)) {
      if (media.naturalWidth < this.props.minWidth || media.naturalHeight < this.props.minHeight) {
        message = _.assign(message, {
          warning: true,
          content: `画像のサイズは ${this.props.minWidth} x ${this.props.minHeight} px 以上にしてください。`,
        })
      }
    } else if (!_.isNil(this.props.minWidth) && _.isNil(this.props.minHeight)) {
      if (media.naturalWidth < this.props.minWidth) {
        message = _.assign(message, {
          warning: true,
          content: `画像のサイズは 幅 ${this.props.minWidth} px 以上にしてください。`,
        })
      }
    } else if (_.isNil(this.props.minWidth) && !_.isNil(this.props.minHeight)) {
      if (media.naturalHeight < this.props.minHeight) {
        message = _.assign(message, {
          warning: true,
          content: `画像のサイズは 高さ ${this.props.minHeight} px 以上にしてください。`,
        })
      }
    }

    // 初期画像 URL から変化がない場合は onChange をコールしない
    if (this.props.mediaUrl !== this.currentMediaUrl) {
      _.invoke(this.props, 'onChange', event, {
        ...this.props,
        mediaUrl: this.currentMediaUrl,
        inputType: this.state.inputType,
      })
    } else {
      // 初回読み込み時には入力タイプのみ通知を行う
      _.invoke(this.props, 'onChange', event, {
        mediaUrl: this.currentMediaUrl,
        inputType: this.state.inputType,
      })
    }

    if (this.isFileSelected) {
      newState = _.assign(newState, {
        isImageModified: false,
        resetUrl: this.currentMediaUrl,
      })
      this.isFileSelected = false
    }
    // エラー状態のセット、ローディングのクリア
    newState = _.assign(newState, { message, isBusy: false })

    this.setState(newState)
  }

  handleSelectFileButtonClick = event => {
    event.preventDefault()
    this.refs.imageUrlInput.click()
  }

  handleSelectStaticImageButtonClick = event => {
    event.preventDefault()

    this.setState({ isStaticImageSelectorOpen: true })
  }

  handleResetButtonClick = event => {
    event.preventDefault()
    this.currentMediaUrl = this.state.resetUrl
    this.setState({
      message: null,
      mediaUrl: this.currentMediaUrl,
      isImageModified: false,
    })
  }

  handleDropFiles = files => {
    let message = null
    const maxSize = this.props.maxSize ? this.props.maxSize * 1024 * 1024 : null
    const file = files[0]
    const reader = new FileReader()

    reader.onload = () => {
      if (_.isEqual(reader.result, this.state.mediaUrl)) {
        this.setState({ isBusy: false })
      } else {
        // 画像読み込み後、this.handleImageLoad に処理が続きます
        this.setState({ mediaUrl: reader.result })
        this.isFileSelected = true
      }
    }

    // キャンセルの場合は file は未定義
    if (file) {
      // エラー or ワーニングチェック
      if (/.+\.mp4$/.test(file.name) && file.type === 'video/mp4' && !_.isNull(maxSize) && maxSize < file.size) {
        // ファイルタイプが動画 (mp4) の場合で設定した最大サイズを超えていた場合はワーニングを表示
        message = _.assign(message, {
          warning: true,
          content: `${this.props.maxSize} MB 以下のファイルを選択してください。`,
        })
      } else if (!/^image\/\w+$/.test(file.type) && !(/.+\.mp4$/.test(file.name) && file.type === 'video/mp4')) {
        // ファイルタイプが画像でも動画 (mp4) でもない場合はエラーを表示
        message = _.assign(message, {
          error: true,
          content: '画像または mp4 形式の動画ファイルを選択してください',
        })
      }

      if (message && message.error) {
        this.currentVideoUrl = ''
        const error = new Error(message.content)

        this.setState({ message, mediaUrl: this.currentMediaUrl })
        _.invoke(
          this.props,
          'onChange',
          {},
          {
            ...this.props,
            mediaUrl: this.currentMediaUrl,
            error,
          },
        )
      } else {
        reader.readAsDataURL(file)
        const inputType = /^image\/\w+$/.test(file.type) ? MediaInput.InputType.IMAGE : MediaInput.InputType.VIDEO
        // ローディングのスタート
        this.setState({ isBusy: true, inputType, message })
      }
    }
  }

  renderPreviewImage = () => (
    <Popup
      content="画像を編集できます。"
      position="left center"
      inverted
      hideOnScroll
      trigger={
        <Image
          className="MediaInput__PreviewImage"
          centered
          src={this.state.mediaUrl}
          onLoad={this.handleMediaLoad}
          onError={this.handleImageError}
          onClick={this.handleImageClick}
        />
      }
    />
  )

  render() {
    const selectButtonIcon = _.isEqual(this.state.inputType, MediaInput.InputType.IMAGE)
      ? 'file image outline'
      : 'file video outline'

    return (
      <div className="MediaInput">
        <Dimmer inverted active={this.state.isBusy}>
          <Loader>読み込み中</Loader>
        </Dimmer>

        {/* 画像選択の場合 */}
        {this.state.mediaUrl && _.isEqual(this.state.inputType, MediaInput.InputType.IMAGE) && (
          <Modal
            size="large"
            open={this.state.isModalOpen}
            onClose={this.handleModalClose}
            trigger={this.renderPreviewImage()}
          >
            <Modal.Content>
              <ImageCropper
                src={this.state.mediaUrl}
                aspectRatio={this.props.aspectRatio}
                minWidth={this.props.minWidth}
                minHeight={this.props.minHeight}
                onComplete={this.handleImageCropperComplete}
              />
            </Modal.Content>
          </Modal>
        )}

        {/* 動画選択の場合 */}
        {this.state.mediaUrl && _.isEqual(this.state.inputType, MediaInput.InputType.VIDEO) && (
          <video
            className="MediaInput____PreviewVideo"
            src={this.state.mediaUrl}
            width="100%"
            controls
            onLoadedData={this.handleMediaLoad}
          />
        )}

        {this.state.message && <Message {...this.state.message} visible className="MediaInput__Message" />}

        <input
          className="isHidden"
          type="file"
          name="imageUrl"
          ref="imageUrlInput"
          accept={_.join(this.props.accepts, ',')}
          onChange={this.handleSelectFileChange}
        />

        {this.props.isDragDrop ? (
          <Dropzone onDrop={this.handleDropFiles.bind(this)} style={{ width: '100%', height: '100%' }}>
            {({ isDragActive, isFocused }) => (
              <Segment
                placeholder
                style={{
                  cursor: 'pointer',
                  borderWidth: '2px',
                  borderStyle: 'dashed',
                  borderColor: isFocused || isDragActive ? '#2185d0' : 'rgba(118, 118, 118, 0.5)',
                  textAlign: 'center',
                }}
              >
                <Header as="h4" icon color="blue" style={{ margin: '0' }}>
                  {isDragActive ? 'ドラッグ中…' : 'ここへドラッグ＆ドロップ'}
                </Header>
                <Divider horizontal color="grey" style={{ width: '40%', margin: '1rem auto' }}>
                  Or
                </Divider>
                <Button
                  type="button"
                  icon={selectButtonIcon}
                  labelPosition="left"
                  content="ファイルを選択"
                  color="blue"
                  style={{ minWidth: '50%' }}
                />
              </Segment>
            )}
          </Dropzone>
        ) : (
          <Button
            type="button"
            content="ファイルを選択"
            icon={selectButtonIcon}
            labelPosition="left"
            onClick={this.handleSelectFileButtonClick}
          />
        )}

        {!_.isEmpty(this.props.staticImageUrls) && (
          <Button
            type="button"
            content="画像から選ぶ"
            icon="file image outline"
            labelPosition="left"
            onClick={this.handleSelectStaticImageButtonClick}
          />
        )}

        {this.state.isImageModified && this.state.resetUrl && (
          <Button type="button" content="戻す" icon="undo" labelPosition="left" onClick={this.handleResetButtonClick} />
        )}

        <StaticImageSelector
          open={this.state.isStaticImageSelectorOpen}
          imageUrls={this.props.staticImageUrls}
          onSelect={this.handleStaticImageSelect}
          onClose={this.handleStaticImageClose}
        />
      </div>
    )
  }
}

MediaInput.propTypes = propTypes
MediaInput.defaultProps = defaultProps

export default MediaInput
