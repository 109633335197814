import React, { Component } from 'react'
import {
  Dimmer,
  Loader,
  Header,
  Segment,
  Divider,
  Icon,
  Label,
  Menu,
  Popup,
  Button,
  Checkbox,
  Message,
  Image,
  Grid,
} from 'semantic-ui-react'
import { Form, Input } from 'formsy-semantic-ui-react'
import _ from 'lodash'
import { FormattedDate, FormattedTime } from 'react-intl'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import classNames from 'classnames'

import ArticleDescriptionEditor from '../../components/ArticleDescriptionEditor'
import ApiErrorMessage from '../../components/ApiErrorMessage'
import MediaInput from '../../components/MediaInput'

import 'react-bootstrap-daterangepicker/css/daterangepicker.css'
import './Article.css'

class Article extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isShowSideBar: false,
    }
  }

  handleShowSidebar = () => {
    this.setState({ isShowSideBar: true })
  }

  handleHideSidebar = () => {
    this.setState({ isShowSideBar: false })
  }

  render() {
    return (
      <div className="article">
        <Form
          className="article__form"
          ref={this.props.setRef}
          noValidate
          onChange={this.props.handleFormChange}
          onValid={this.props.handleFormValid}
          onInvalid={this.props.handleFormInvalid}
          onValidSubmit={this.props.handleFormValidSubmit}
        >
          <div className="article__main custom-scroll-bar">
            <Segment className="article__main__header">
              <Grid>
                <Grid.Column floated="left" width={14}>
                  <Header as="h1">
                    <Icon name="edit" />
                    <Header.Content>記事の編集</Header.Content>
                    <Label color="teal" size="large" image>
                      記事ID
                      <Label.Detail>{this.props.articleId}</Label.Detail>
                    </Label>
                  </Header>
                </Grid.Column>
                <Grid.Column floated="right" width={2}>
                  <Button
                    type="button"
                    icon="content"
                    floated="right"
                    className="article__main__header__menu-button"
                    onClick={this.handleShowSidebar}
                  />
                  <Button
                    primary
                    circular
                    type="submit"
                    content="記事を更新"
                    icon="save"
                    floated="right"
                    labelPosition="left"
                    className="article__main__header__submit-button"
                    disabled={
                      !this.props.isFormValid ||
                      this.props.isDuringSubmit ||
                      !this.props.isFormModified ||
                      this.props.descriptionError
                    }
                    onClick={this.props.onUpdateButton}
                  />
                </Grid.Column>
              </Grid>
            </Segment>

            <Dimmer active={this.props.isBusy} inverted page={this.props.isDuringSubmit}>
              <Loader>読み込み中</Loader>
            </Dimmer>

            <div className="article__main__sub-header">
              <Button
                primary
                circular
                type="submit"
                content="記事を更新"
                icon="save"
                labelPosition="left"
                className="article__main__sub-header__submit-button"
                disabled={
                  !this.props.isFormValid ||
                  this.props.isDuringSubmit ||
                  !this.props.isFormModified ||
                  this.props.descriptionError
                }
                onClick={this.props.onUpdateButton}
              />
            </div>

            <div className="article__main__content">
              <Form.Input name="title" label="タイトル" placeholder="タイトルを入力してください" required />

              <Form.TextArea
                name="summary"
                label="概要"
                placeholder="概要を入力してください"
                autoHeight
                rows={1}
                required
              />

              <Form.Field required error={!_.isNil(this.props.descriptionError)}>
                <label>本文</label>

                <Segment className={classNames({ error: this.props.descriptionError })}>
                  {this.props.isArticleInitialized && (
                    <ArticleDescriptionEditor
                      description={this.props.descriptionInputValue}
                      onInit={this.props.handleDescriptionEditorInit}
                      onChange={this.props.handleDescriptionEditorChange}
                      fireByteLengthWarning={this.props.handleEditorWarning}
                    />
                  )}

                  {!_.isNil(this.props.descriptionError) && (
                    <Label color="red" attached="top right">
                      {this.props.descriptionError.message}
                    </Label>
                  )}
                </Segment>

                <Input
                  className="isHidden"
                  name="description"
                  placeholder="本文を入力してください"
                  required
                  readOnly
                  value={this.props.descriptionInputValue}
                />
              </Form.Field>

              <ApiErrorMessage error={this.props.apiError} />

              <Message as={Message} warning animation="overlay" direction="bottom" visible={this.props.editorWarning}>
                更新ボタンを押して記事を保存してください。
              </Message>
            </div>
          </div>

          <div className={`article__sidebar${this.state.isShowSideBar ? ' article__sidebar--visible' : ''}`}>
            <Header as="h2" content="情報" />

            <Header as="h4" content="最終更新" color="grey" />
            {this.props.updatedAt && (
              <p style={{ fontSize: '1.25rem' }}>
                <FormattedDate
                  value={this.props.updatedAt}
                  day="numeric"
                  month="long"
                  year="numeric"
                  weekday="narrow"
                />
                &nbsp;
                <FormattedTime value={this.props.updatedAt} hour="numeric" minute="numeric" second="numeric" />
              </p>
            )}
            <Divider hidden />

            <Button
              fluid
              type="button"
              icon="external"
              color="blue"
              onClick={this.props.handleOpenAdminSiteButtonClick}
              content="TRILL記事"
              labelPosition="right"
              disabled={!this.props.isFormValid || this.props.isDuringSubmit || this.props.descriptionError}
            />
            <Divider hidden />

            <Divider />
            <Menu pointing secondary color="blue" className="custom-scroll-bar">
              <Menu.Item
                content="プレビュー"
                name="preview"
                onClick={this.props.handleSidebarMenuItemClick}
                active={_.isEqual(this.props.viewSidebarStatus, 'preview')}
              />
              <Menu.Item
                content="画像・動画"
                name="media"
                onClick={this.props.handleSidebarMenuItemClick}
                active={_.isEqual(this.props.viewSidebarStatus, 'media')}
              />
              <Menu.Item
                content="記事情報"
                name="meta"
                onClick={this.props.handleSidebarMenuItemClick}
                active={_.isEqual(this.props.viewSidebarStatus, 'meta')}
              />
            </Menu>

            <Divider hidden />

            <div
              style={{
                display: _.isEqual(this.props.viewSidebarStatus, 'preview') ? '' : 'none',
              }}
              className="article__sidebar__menu__tab custom-scroll-bar"
            >
              <Header as="h3" content="プレビュー" />

              <Segment padded>
                <Label attached="top left">プレビュー</Label>

                <div className="article-information__preview-button">
                  {this.props.previewUrl && (
                    <Button
                      circular
                      fluid
                      icon="eye"
                      color="blue"
                      as="a"
                      href={this.props.previewUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      content="プレビュー"
                      labelPosition="left"
                      disabled={!this.props.isPreviewed}
                    />
                  )}

                  <Divider hidden />

                  {this.props.qrcode && <Image src={this.props.qrcode} fluid />}
                </div>
              </Segment>
            </div>

            <div
              style={{
                display: _.isEqual(this.props.viewSidebarStatus, 'media') ? '' : 'none',
              }}
              className="article__sidebar__menu__tab custom-scroll-bar"
            >
              <Header as="h3" content="画像・動画" />

              <Segment.Group>
                <Segment>
                  <Form.Field error={!_.isNil(this.props.thumbnailImageError)} style={{ marginBottom: 0 }}>
                    <label>{this.props.isCoverImageActive ? 'サムネイル画像' : 'サムネイル・カバー画像'}</label>

                    <MediaInput
                      isDragDrop={true}
                      mediaUrl={this.props.thumbnailImageUrlInputValue}
                      minWidth={640}
                      minHeight={396}
                      onChange={this.props.handleThumbnailImageInputChange}
                    />

                    <Input
                      className="isHidden"
                      name="thumbnail.image.url"
                      placeholder="サムネイル画像を選択してください"
                      readOnly
                      value={this.props.thumbnailImageUrlInputValue}
                    />
                  </Form.Field>

                  <div
                    style={{
                      marginTop: '1rem',
                      display: this.props.thumbnailImageUrlInputValue ? '' : 'none',
                    }}
                  >
                    <Form.Input
                      name="thumbnail.image.copyright.title"
                      label="出典元"
                      placeholder="出典元を入力してください"
                    />

                    <Form.Input
                      name="thumbnail.image.copyright.url"
                      label="出典元の URL"
                      placeholder="出典元の URL を入力してください"
                      validations="isUrl"
                      validationErrors={{
                        isUrl: '無効な URL です',
                      }}
                      errorLabel={<Label color="red" pointing />}
                    />
                  </div>
                </Segment>

                <Segment>
                  <Checkbox
                    toggle
                    label="カバー画像を別の画像に設定"
                    checked={this.props.isCoverImageActive}
                    onChange={this.props.handleCoverImageToggleChange}
                  />
                </Segment>

                <Segment
                  style={{
                    display: this.props.isCoverImageActive ? '' : 'none',
                  }}
                >
                  <Form.Field error={!_.isNil(this.props.coverImageError)} style={{ marginBottom: 0 }}>
                    <label>カバー画像</label>

                    <MediaInput
                      isDragDrop={true}
                      mediaUrl={this.props.coverImageUrlInputValue}
                      minWidth={640}
                      minHeight={396}
                      onChange={this.props.handleCoverImageInputChange}
                    />

                    <Input
                      className="isHidden"
                      name="cover.image.url"
                      placeholder="カバー画像を選択してください"
                      readOnly
                      value={this.props.coverImageUrlInputValue}
                    />
                  </Form.Field>

                  <div
                    style={{
                      marginTop: '1rem',
                      display: this.props.coverImageUrlInputValue ? '' : 'none',
                    }}
                  >
                    <Form.Input
                      name="cover.image.copyright.title"
                      label="出典元"
                      placeholder="出典元を入力してください"
                    />

                    <Form.Input
                      name="cover.image.copyright.url"
                      label="出典元の URL"
                      placeholder="出典元の URL を入力してください"
                      validations="isUrl"
                      validationErrors={{
                        isUrl: '無効な URL です',
                      }}
                      errorLabel={<Label color="red" pointing />}
                    />
                  </div>
                </Segment>
              </Segment.Group>

              <Segment.Group>
                <Segment>
                  <Form.Checkbox
                    toggle
                    name="options.video.active"
                    label="動画記事に設定"
                    checked={this.props.isVideoArticleActive}
                    onChange={this.props.handleVideoArticleToggleChange}
                  />
                </Segment>

                <Segment
                  style={{
                    display: this.props.isVideoArticleActive ? '' : 'none',
                  }}
                >
                  <Form.Field>
                    <label>サムネイル動画</label>

                    <Popup
                      inverted
                      content="サムネイル画像に設定している画像をサムネイルとして表示します"
                      trigger={
                        <Form.Checkbox
                          toggle
                          name="options.video.image.use"
                          label="サムネイル動画に画像を設定"
                          checked={this.props.isVideoArticleImageUse}
                          onChange={this.props.handleVideoArticleThumbnailStateCheckChange}
                        />
                      }
                    />
                  </Form.Field>

                  <Form.Field
                    style={{
                      marginBottom: 0,
                      display: this.props.isVideoArticleImageUse ? '' : 'none',
                    }}
                    error={!_.isNil(this.props.thumbnailVideoError)}
                  >
                    <MediaInput
                      mediaUrl={this.props.thumbnailVideoUrlInputValue}
                      maxSize={1}
                      minWidth={640}
                      minHeight={396}
                      accepts={['video/mp4']}
                      onChange={this.props.handleThumbnailVideoInputChange}
                    />

                    <Input
                      className="isHidden"
                      name="thumbnail.video.url"
                      placeholder="サムネイル動画を選択してください"
                      readOnly
                      required={this.props.isVideoArticleActive && !this.props.isVideoArticleImageUse}
                      value={this.props.thumbnailVideoUrlInputValue}
                    />
                  </Form.Field>

                  <div
                    style={{
                      marginTop: '1rem',
                      display:
                        this.props.thumbnailVideoUrlInputValue && !this.props.isVideoArticleImageUse ? '' : 'none',
                    }}
                  >
                    <Form.Input
                      name="thumbnail.video.copyright.title"
                      label="出典元"
                      placeholder="出典元を入力してください"
                    />

                    <Form.Input
                      name="thumbnail.video.copyright.url"
                      label="出典元の URL"
                      placeholder="出典元の URL を入力してください"
                      validations="isUrl"
                      validationErrors={{
                        isUrl: '無効な URL です',
                      }}
                      errorLabel={<Label color="red" pointing />}
                    />
                  </div>
                </Segment>

                <Segment
                  style={{
                    display: this.props.isVideoArticleActive ? '' : 'none',
                  }}
                >
                  <Form.Field>
                    <label>
                      カバー動画
                      <Popup
                        inverted
                        content="カバー動画の候補となる URL を再検出します"
                        trigger={
                          <Icon
                            style={{ float: 'right' }}
                            link
                            onClick={this.props.handleCoverVideoRefreshIconClick}
                            name="refresh"
                          />
                        }
                      />
                    </label>

                    <Form.Dropdown
                      loading={this.props.loadingCoverVideoUrls}
                      error={!_.isNil(this.props.coverVideoError)}
                      name="cover.video.url"
                      placeholder="カバー動画を選択してください"
                      selection
                      options={this.props.coverVideoUrlDropdownOptions}
                      value={this.props.coverVideoUrlDropdownValue}
                      onChange={this.props.handleCoverVideoUrlDropdownChange}
                      required={this.props.isVideoArticleActive}
                    />
                  </Form.Field>

                  <div
                    style={{
                      marginTop: '1rem',
                      display: this.props.coverVideoUrlDropdownValue ? '' : 'none',
                    }}
                  >
                    <iframe
                      title="カバー動画"
                      src={this.props.coverVideoUrlDropdownValue}
                      width="100%"
                      frameBorder="0"
                    />

                    <Form.Input
                      name="cover.video.copyright.title"
                      label="出典元"
                      placeholder="出典元を入力してください"
                    />

                    <Form.Input
                      name="cover.video.copyright.url"
                      label="出典元の URL"
                      placeholder="出典元の URL を入力してください"
                      validations="isUrl"
                      validationErrors={{
                        isUrl: '無効な URL です',
                      }}
                      errorLabel={<Label color="red" pointing />}
                    />
                  </div>
                </Segment>
              </Segment.Group>
            </div>

            <div
              style={{
                display: _.isEqual(this.props.viewSidebarStatus, 'meta') ? '' : 'none',
              }}
              className="article__sidebar__menu__tab custom-scroll-bar"
            >
              <Header as="h3" content="記事情報" />

              <Form.Field>
                <label>公開日時</label>

                <DateRangePicker
                  containerStyles={{ display: 'block' }}
                  singleDatePicker
                  timePicker
                  timePicker24Hour
                  locale={{
                    applyLabel: '確定',
                    cancelLabel: '解除',
                  }}
                  startDate={this.props.publishDatetime}
                  endDate={this.props.publishDatetime}
                  onEvent={this.props.handleDatePickerEvent}
                >
                  <Input
                    icon="calendar"
                    iconPosition="left"
                    placeholder="公開日時を指定"
                    name="publishDatetime"
                    value={this.props.publishDatetimeInputValue}
                    readOnly
                  />
                </DateRangePicker>
              </Form.Field>
            </div>
          </div>

          <div
            className={`article__sidebar-backdrop${
              this.state.isShowSideBar ? ' article__sidebar-backdrop--visible' : ''
            }`}
            onClick={this.handleHideSidebar}
          >
            <Button
              type="button"
              icon="close"
              className="article__sidebar-backdrop__close-button"
              onClick={this.handleHideSidebar}
            />
          </div>
        </Form>
      </div>
    )
  }
}

export default Article
