import LogLevel from 'loglevel'

const originalFactory = LogLevel.methodFactory

LogLevel.methodFactory = (methodName, logLevel, loggerName) => {
  const rawMethod = originalFactory(methodName, logLevel, loggerName)

  return (...message) => {
    rawMethod(`${loggerName}:`, ...message)
  }
}

if (process.env.NODE_ENV === 'development') {
  LogLevel.setDefaultLevel(LogLevel.levels.TRACE)
} else {
  LogLevel.setDefaultLevel(LogLevel.levels.INFO)
}

export default LogLevel
