import React, { Component } from 'react'
import { Router, Route, browserHistory } from 'react-router'
import { Loader } from 'semantic-ui-react'

// React Intl - 文字列フォーマット・ライブラリ（多言語対応もしてくれる）
// 参考：https://medium.freecodecamp.com/internationalization-in-react-7264738274a0#.l7xa9xobr
import { IntlProvider, addLocaleData } from 'react-intl'
import en from 'react-intl/locale-data/en'
import ja from 'react-intl/locale-data/ja'

// Semantic UI のスタイル読み込み
// TODO: テーマをカスタマイズする場合は、別リポジトリで開発したパッケージを読み込む
import 'semantic-ui-css/semantic.min.css'

import moment from 'moment'
import 'moment/locale/ja'

import store from 'store'
import storeObservePlugin from 'store/plugins/observe'

import Article from './views/Main/ArticleContainer'
import Error401 from './views/Main/Error401'
import Error403 from './views/Main/Error403'
import Error404 from './views/Main/Error404'
import Error500 from './views/Main/Error500'

import LogLevel from './LogLevel'

import './App.css'

// 言語データ設定
addLocaleData([...en, ...ja])

// 言語判定
const language = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage

// Moment の言語設定
moment.locale(language)

// Store モジュールを初期化 (https://www.npmjs.com/package/store-js#using-plugins)
store.addPlugin(storeObservePlugin)

const logger = LogLevel.getLogger('App')

class App extends Component {
  state = {
    isInitialized: false,
  }

  componentDidMount() {
    this.setState({ isInitialized: true })
  }

  handleRouteEnter = (nextState, replace) => {
    logger.debug('handle route enter', nextState)
  }

  render() {
    if (this.state.isInitialized) {
      return (
        <IntlProvider locale={language}>
          <Router history={browserHistory}>
            <Route path="article/:id" component={Article} onEnter={this.handleRouteEnter} />
            <Route path="401" component={Error401} onEnter={this.handleRouteEnter} />
            <Route path="403" component={Error403} onEnter={this.handleRouteEnter} />
            <Route path="404" component={Error404} onEnter={this.handleRouteEnter} />
            <Route path="500" component={Error500} onEnter={this.handleRouteEnter} />
          </Router>
        </IntlProvider>
      )
    }
    return <Loader active>読み込み中....</Loader>
  }
}

export default App
