import React from 'react'
import ReactDOM from 'react-dom'
import LogLevel from './LogLevel'
import App from './App'
import _ from 'lodash'
import './index.css'

// ロギングモジュールの読み込み（参考：https://github.com/pimterry/loglevel）
window.LogLevel = LogLevel

// 開発モードの場合のみ React パフォーマンス計測ツールを読み込む
// （参考：https://github.com/facebook/react/issues/6174#issuecomment-192552614）
if (process.env.NODE_ENV === 'development') {
  window.ReactPerf = require('react-addons-perf') // eslint-disable-line global-require, import/no-extraneous-dependencies
}

const buildEnv = _.defaultTo(process.env.REACT_APP_BUILD_ENV, 'local')
switch (buildEnv) {
  case 'development':
    document.title = 'DEV | TRILL Outsourced Article Editor'
    break
  case 'staging':
    document.title = 'STG | TRILL Outsourced Article Editor'
    break
  case 'production':
    document.title = 'PROD | TRILL Outsourced Article Editor'
    break
  default:
    document.title = 'LOCL | TRILL Outsourced Article Editor'
}

ReactDOM.render(<App />, document.getElementById('root'))
