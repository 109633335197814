import React, { Component } from 'react'
import { Modal, Image, Grid } from 'semantic-ui-react'
import _ from 'lodash'

import PropTypes from 'prop-types'

const propTypes = {
  /**
   * 選択する画像の URL
   */
  imageUrls: PropTypes.array,

  /**
   * 画像選択時の外部関数
   *
   * @param {string} - 選択した画像の URL
   */
  onSelect: PropTypes.func,

  /**
   * トリガーとなるもの
   */
  onClose: PropTypes.func,

  /**
   * モーダルの表示状態
   */
  open: PropTypes.bool,
}

const defaultProps = {
  imageUrls: [],
}

class StaticImageSelector extends Component {
  handleModalClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  clickImage(imageUrl) {
    if (this.props.onSelect) {
      this.props.onSelect(imageUrl)
    }
  }

  render() {
    return (
      <Modal open={this.props.open} basic size="fullscreen" onClose={this.handleModalClose}>
        <Modal.Content>
          <Grid columns={4} centered>
            {_.map(this.props.imageUrls, (imageUrl, key) => (
              <Grid.Column key={key}>
                <Image
                  src={imageUrl}
                  onClick={() => {
                    this.clickImage(imageUrl)
                  }}
                />
              </Grid.Column>
            ))}
          </Grid>
        </Modal.Content>
      </Modal>
    )
  }
}

StaticImageSelector.propTypes = propTypes
StaticImageSelector.defaultProps = defaultProps

export default StaticImageSelector
